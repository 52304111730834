import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import { processingModal } from "src/nextgen/helpers/processing_modal";

class NeoModalController extends Controller {
  connect() {
    this.inProgress = false;
    this.prefix = this.element.dataset.prefix;
    this.modal = $(this.element);
    this.form = this.element.querySelector("form");
    this.inProgressElements = this.element.querySelectorAll(".in_progress_shadow");
    this.skipFormSubmit = JSON.parse(this.element.dataset.skipFormSubmit || false); // ????
    this.deferSubmit = JSON.parse(this.element.dataset.deferSubmit || false);
    this.hideOnSuccess = JSON.parse(this.element.dataset.hideOnSuccess || false);

    if (this.form && !this.skipFormSubmit) {
      this.form.addEventListener('submit', (e) => {
        e.preventDefault();

        if (this.deferSubmit) {
          const submittingEvent = new CustomEvent('neo_modal:submitting_form', { 
            detail: { 
              submitFunction: () => { return this.sendForm() },
              errorFunction: (error) => { return this.handleError(error) },
              successFunction: (response) => { return this.onSuccess(response) }
            } 
          });
          this.element.dispatchEvent(submittingEvent);
        } else {
          this.sendForm();
        }
      })
    }

    this.modal.modal('show');
    this.modal.modal({ backdrop: 'static', keyboard: false });

    this.modal.on('hide.bs.modal', (e) => {
      if (this.inProgress) e.preventDefault();
    });

    this.modal.on('hidden.bs.modal', (e) => {
      e.preventDefault();
      this.element.remove();
    })
  }

  sendForm() {
    const form = this.form;
    const url = form.getAttribute('action');

    if (this.inProgress) return true;
    if (url === null) return true;

    this.setInProgress(true);
    this.removeErrors();

    const options = {
      method: form.getAttribute('method') || 'POST',
      url: form.getAttribute('action'),
      data: new FormData(form)
    }

    if (this.deferSubmit) {
      return axios(options);
    } else {
      axios(options)
      .then((response) => this.onSuccess(response))
      .catch((error) => this.handleError(error));

      return null;
    }
  }
  
  handleError(error) {
    this.setInProgress(false);

    const { response } = error;

    if (response) {
      if (response.status === 422) {
        this.addErrors((response.data || {}).errors)
        const submit = this.form.querySelector('input[type="submit"]');
        submit.disabled = false;
      } else {
        Helper.flash_message('error', "Something went wrong")
      }
    }

    this.onFailed(error);
  }

  addErrors(errors) {
    const prefix = this.prefix;
    const form = this.form;

    Object.keys(errors).forEach((key) => {
      const value = errors[key];

      if (Array.isArray(value)) {
        const messages = value.join(', ');
      } else {
        const messages = value;
      }

      if (key === 'base') {
        const messageBlock =  form.querySelector('.base_error_message');

        if (messageBlock) {
          messageBlock.innerHTML = `<span class="text-danger">${messages}</span>`;
        }
      } else {
        const selector = '#' + [prefix, key].filter(el => el).join('_');
        const element = form.querySelector(selector);

        if (element === null) return;

        const formGroup = form.querySelector(selector).closest('.form-group');

        if (formGroup === null) return;

        const errorMessage = document.createElement('span');
        errorMessage.className = 'text-danger';
        errorMessage.textContent = messages;

        formGroup.classList.add('has-error');
        formGroup.appendChild(errorMessage);
      }
    });
  }

  removeErrors() {
    const form = this.form;
    const errorGroups = form.querySelectorAll('.has-error');
    const errorMessages = form.querySelectorAll('.text-danger');

    errorGroups.forEach((errorGroup) => errorGroup.classList.remove('has-error'));
    errorMessages.forEach((errorMessage) => errorMessage.parentNode.removeChild(errorMessage));
  }

  setInProgress(status) {
    this.inProgress = status;

    if (this.inProgress) {
      processingModal.show(0);
      this.inProgressElements.forEach(element => element.classList.remove('hidden'));
    } else {
      processingModal.hide();
      this.inProgressElements.forEach(element => element.classList.add('hidden'));
    }
  }

  onSuccess(response) {
    this.setInProgress(false);
    const successEvent = new CustomEvent('neo_modal:success', { detail: { response: response } });
    this.element.dispatchEvent(successEvent);

    if (this.hideOnSuccess) {
      this.modal.modal('hide');
    }
  }

  onFailed(error) {
    const errorEvent = new CustomEvent('neo_modal:error', { detail: { error: error } });
    this.element.dispatchEvent(errorEvent);
  }
}

export default NeoModalController;
